import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { adopt } from 'react-adopt';
import { COLLECTIONS_ORDER_BY, GET_COLLECTIONS } from 'src/queries/collections';
import { GET_FOLDERS } from 'src/queries/folders';
import { GET_PROPOSED_COLLECTIONS } from 'src/queries/propose';
import { GET_CURRENT_USER } from 'src/queries/users';
import Content from './Content';

const COLLECTIONS_LIMIT = 6;
export const FOLDERS_LIMIT = 6;

class StudentDashboardContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;

  render() {
    const { user } = this.props;

    /* eslint-disable */
    const queries = {
      userQuery: ({ render }) => (
        <Query query={GET_CURRENT_USER} fetchPolicy={'cache-and-network'} variables={{ withStudentStatistics: true }}>
          {render}
        </Query>
      ),
      recommendations: ({ render }) => (
        <Query
          query={GET_COLLECTIONS}
          fetchPolicy={'cache-and-network'}
          variables={{
            limit: COLLECTIONS_LIMIT,
            filterBy: [
              { property: 'isOfficial', where: "true" },
              { property: 'isPublished', where: "true" },
              { join: 'schoolDegrees', property: 'id', where: user.schoolDegree.id },
            ],
            orderBy: [COLLECTIONS_ORDER_BY['newest']],
          }}
        >
          {render}
        </Query>
      ),
      proposedCollections: ({ render }) => (
        <Query query={GET_PROPOSED_COLLECTIONS} fetchPolicy={'cache-and-network'} pollInterval={3000}>
          {render}
        </Query>
      ),
      subscriptions: ({ render }) => (
        <Query
          query={GET_FOLDERS}
          fetchPolicy={'network-only'}
          variables={{
            limit: FOLDERS_LIMIT,
            filterBy: [{ join: 'linkUsers', property: 'user', where: user.id }],
          }}
        >
          {render}
        </Query>
      ),
    };

    const Composed = adopt(queries);
    /* eslint-enable */

    return (
      <Composed>
        {({ recommendations, proposedCollections, subscriptions, userQuery }) => {
          if ((recommendations.loading || proposedCollections.loading || subscriptions.loading || userQuery.loading) && !this.isContentLoaded)
            return <div />;
          if (recommendations.error || proposedCollections.error || subscriptions.error || userQuery.error) return <p>Error :(</p>;
          this.isContentLoaded = true;
          const dataRecommendations = recommendations.data;
          const dataSubscriptions = subscriptions.data;
          const dataProposedCollections = proposedCollections.data;
          const userData = userQuery.data && userQuery.data.me;

          return (
            <Content
              dataRecommendations={dataRecommendations}
              dataSubscriptions={dataSubscriptions}
              dataProposedCollections={dataProposedCollections}
              userData={userData}
            />
          );
        }}
      </Composed>
    );
  }
}

export default StudentDashboardContainer;
