import gql from 'graphql-tag';

import { AVATAR_FRAGMENT } from './avatars';

export const USERS = gql`
  query GetUsers(
    $page: Int = 1
    $limit: Int
    $filterBy: [FilterBy]
    $orderBy: [OrderBy]
    $onlyStudent: Boolean = false
  ) {
    users(page: $page, limit: $limit, filterBy: $filterBy, orderBy: $orderBy, onlyStudent: $onlyStudent) {
      count
      results {
        id
        firstname
        lastname
        username
        avatar {
          ...AvatarParts
        }
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const GET_STUDENT = gql`
  query GetStudent($id: ID!, $withUserLinkCards: Boolean = false) {
    student(id: $id) {
      id
      email
      firstname
      lastname
      username
      avatar {
        ...AvatarParts
      }
      userLinkCards @include(if: $withUserLinkCards) {
          card {
            id
          }
          status
          failed
        }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const GET_STUDENTS = gql`
  query GetStudents($page: Int = 1, $limit: Int, $filterBy: [FilterBy], $orderBy: [OrderBy]) {
    students(page: $page, limit: $limit, filterBy: $filterBy, orderBy: $orderBy) {
      count
      results {
        id
        email
        firstname
        lastname
        username
        avatar {
          ...AvatarParts
        }
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

// TODO Add with condition to avoid getting school data on every page ?
export const GET_CURRENT_USER = gql`
  query GetCurrentUser(
    $withFriends: Boolean = false
    $withLinkCollections: Boolean = false
    $withTeacherStatistics: Boolean = false
    $withStudentStatistics: Boolean = false
  ) {
    me {
      id
      email
      firstname
      lastname
      username
      roles
      score
      allTimeScore
      hasBeenReset
      schools {
        id
        name
        schoolLevel {
          id
          name
          schoolDegrees {
            id
            name
          }
        }
      }
      schoolLevel {
        id
        name
      }
      schoolDegree {
        id
        name
      }
      linkCollections @include(if: $withLinkCollections) {
        id
        collection {
          id
        }
        playedMemo
        playedAsso
        playedTypo
        playedBrio
        scoreMemo
        scoreAsso
        scoreTypo
        scoreBrio
        timeMemo
        timeAsso
        timeTypo
        timeBrio
      }
      countCollectionsWithToReviewStatus
      friends @include(if: $withFriends) {
        id
        firstname
        username
        avatar {
          ...AvatarParts
        }
      }
      schoolSubjects {
        id
        name
      }
      avatar {
        ...AvatarParts
      }
      countCreatedCollections @include(if: $withTeacherStatistics)
      countCreatedAndPublishedCollections @include(if: $withTeacherStatistics)
      countPlayersForCollections @include(if: $withTeacherStatistics)
      countPlayersIterationForCollections @include(if: $withTeacherStatistics)
      countNewCollections @include(if: $withStudentStatistics)
      countWaitingDuels
      countDuels @include(if: $withStudentStatistics)
      countWinningDuels @include(if: $withStudentStatistics)
      countCollections @include(if: $withStudentStatistics)
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
      hasBeenReset
      schoolDegree {
        id
        name
      }
      schoolLevel {
        id
        name
      }
      schoolSubjects {
        id
        name
      }
      friends {
        id
        avatar {
          ...AvatarParts
        }
      }
      avatar {
        ...AvatarParts
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const GET_RANKINGS = gql`
  query GetRankinks($collection: ID!, $game: String!) {
    rankings(collection: $collection, game: $game) {
      firstRank
      users {
        avatar {
          id
          image
        }
        id
        firstname
        lastname
        score
        linkCollections {
          collection {
            id
          }
          scoreAsso
          scoreBrio
          scoreMemo
          scoreTypo
          timeAsso
          timeBrio
          timeMemo
          timeTypo
        }
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser {
    removeUser
  }
`;

export const GET_USER_WAITING_DUELS = gql`
  query GetCurrentUserWaitingDuels {
    me {
      id
      countWaitingDuels
    }
  }
`;
